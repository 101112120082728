import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharingmessageService {

  constructor() { }
  
  private data:any = "";
  private fileData:any[] =[];
  private isPinned: boolean = false;

  private bs = new BehaviorSubject(this.data);
  private bs2 = new BehaviorSubject(this.fileData);
  private isPinnedSubject = new BehaviorSubject(this.isPinned);
  
  obs = this.bs.asObservable();
  obs2 = this.bs2.asObservable();
  isPinnedObs = this.isPinnedSubject.asObservable();
 
  changeData(editedData: any) {

    this.bs.next(editedData);

  }
  setData(data:any){
      this.data = data; 
  }

  getData():any{
      return this.data;
        }
  setFileData(fileData:any){
    this.bs2.next(fileData);
  }
  getFileData(): any {
    return this.fileData;
  }

  setIsPinned(isPinned: boolean) {
    this.isPinned = isPinned;
    this.isPinnedSubject.next(isPinned);
  }
 
  getIsPinned(): boolean {
    return this.isPinned;
  }
}