import { Component, OnInit, OnDestroy, Inject, ElementRef, Renderer2, AfterContentChecked, AfterViewInit, enableProdMode, ViewChild, HostListener } from '@angular/core';
import { CardOptions } from '@usitsdasdesign/dds-ng/cards';
import { WidthState, Themes, Size, ButtonKind, LabelPosition, ErrorState, TabThemeType } from '@usitsdasdesign/dds-ng/shared';
import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
import { ProfileOptions } from '@usitsdasdesign/dds-ng/profile';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SelectOptions, SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from './environments/environment';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { HttpClient } from '@angular/common/http';
import { SharingmessageService } from './Services/sharingmessage.service';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { LanguageServiceService } from './Services/language-service.service';
import { NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { ChatService } from './Services/chat.service';
import { TabsOptions, TabOptions, TabComponent } from '@usitsdasdesign/dds-ng/tabs';
import { WizardOptions, WizardThemes, WizardItemOptions, WizardItemComponent } from '@usitsdasdesign/dds-ng/wizard';
import { VerticalTabsOptions, VerticalTabOptions, VerticalTabComponent } from '@usitsdasdesign/dds-ng/vertical-tabs';
import { ErrorComponent } from './error/error.component';
import { LoadingService } from './Services/LoadingService';
import { AppComponentService } from './Services/appcomponentservice';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { SystemsettingsComponent } from './systemsettings/systemsettings.component';
import { PreviewPopupComponent } from './preview-popup/preview-popup.component';
import { ResetChatService } from './Services/reset-chat.service';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';

import { HomeComponent } from './home/home.component';
import { WhatsnewComponent } from './whatsnew/whatsnew.component';


// declare var bootstrap: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterContentChecked {
  title = 'Gen-D';
  private destroy = new Subject<void>();
  private teamsChannelUrl: string = 'https://teams.microsoft.com/l/team/19%3AFDYRcbgfOx7MpQhKkMEcwGIA0oJS7DainlCatWTAUjo1%40thread.tacv2/conversations?groupId=a9375551-e68a-4e03-a57b-54f04124fda7&tenantId=36da45f1-dd2c-4d1f-af13-5abe46b99921';

  isSystemSettingsModalOpen: boolean = false;
  currentPath: string;
  text: string = "Token Limit: 13000\nSpeed: High\nReasoning: High\nTraining Upto: Oct 2023";
  selectedLanguage: string = 'EN';
  lblRecentlyUsedAgents: string;
  lblChat: string;
  lblChatHistory: string;
  lblRecent10Conversations: string;
  lblSettings: string;
  lblUserCommunity: string;
  lblChatSettings: string;
  lblNewChat: string;
  lblWhatsNew: string;
  verifyPreviewvalue: boolean =true;

  tooltipVisible: boolean = false;
  txtTooltipHeader: string = 'Usage Limit';
  txtTooltipBody: string = 'Your daily usage limit for the more advanced GPT-4o model is set to 50k tokens, which is roughly 40 pages (~300 words/page). The limit resets for your account every 24 hours. We suggest to use the 4o model only for complex reasoning and other advanced use cases.';
  hidemodelSelect: boolean = true;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private https: HttpClient, private sharingService: SharingmessageService,
    private i18n: I18nService,
    private router: Router,
    private route: ActivatedRoute,
    private chatService: ChatService,
    private elRef: ElementRef, private renderer: Renderer2,
    public loadingService: LoadingService,
    public appcomponentservice: AppComponentService,
    private modalService: ModalService,
    private languageService: LanguageServiceService,
    private resetChatService: ResetChatService,
    private sharingmessageService: SharingmessageService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Access the current path using ActivatedRoute
      this.currentPath = this.router.url; // Get the current URL
    });
  }
  @ViewChild('sidebar') sidebar: ElementRef | undefined;
  @ViewChild('mainContent') mainContent: ElementRef | undefined;
  // @ViewChild('chatinput') chatInput: ElementRef | undefined;
  // @ViewChild('dynamicContent') dynamicContent: ElementRef | undefined;
  @ViewChild('pinIcon', { static: true }) pinIcon!: ElementRef;
  @ViewChild('unpinIcon', { static: true }) unpinIcon!: ElementRef;

  isPinned = true;
  systemModel: string;

  // get tooltipContent(): string {
  //   return `<style></style><div class="tooltip-header">${this.txtTooltipHeader}</div><div class="tooltip-body">${this.txtTooltipBody}</div>`;
  // }

  openWhatsNewModel() {
    if (this.appcomponentservice.isModelOpen){
      this.modalService.close();
      this.appcomponentservice.setIsModelOpen(false);
    }
    this.appcomponentservice.setIsModelOpen(true);
    this.modalService.open(WhatsnewComponent, { lstoptionDislike: WhatsnewComponent }).onClosed().subscribe(res => {
      this.appcomponentservice.setIsModelOpen(false);
    });
  }

  serviceFuncSetIsPinned() {
    this.sharingmessageService.setIsPinned(this.isPinned);
    console.log("this.isSidebarPinned", this.isPinned);

  }

  showTooltip() {
    this.tooltipVisible = true;
  }

  hideTooltip() {
    this.tooltipVisible = false;
  }

  isSelected(path: string): boolean {
    return this.currentPath === path;
    console.log(path, this.currentPath)
  }

  pinSidebar() {
    // Adjust CSS classes
    if(this.pinIcon != undefined){
    this.renderer.removeClass(this.pinIcon.nativeElement, 'hide');
    this.renderer.removeClass(this.pinIcon.nativeElement, 'hidePin');
    this.renderer.addClass(this.unpinIcon.nativeElement, 'hideUnPin');

    // Set isPinned to true
    this.isPinned = true;

    }
  }

  togglePin() {
    const pinIcon = document.getElementById('pin-icon');
    const unpinIcon = document.getElementById('unpin-icon');

    pinIcon?.classList.remove("hide");
    unpinIcon?.classList.remove("hideUnPin");
    pinIcon?.classList.add("hidePin");
    this.isPinned = !this.isPinned;
    if (this.sidebar?.nativeElement.classList.contains('show')) {
      this.appcomponentservice.setIsPinChecked(true);
    }
    else {
      this.appcomponentservice.setIsPinChecked(false);
    }

  }
  toggleUnPin() {
    const pinIcon = document.getElementById('pin-icon');
    const unpinIcon = document.getElementById('unpin-icon');
    pinIcon?.classList.remove("hide");
    pinIcon?.classList.remove("hidePin");
    unpinIcon?.classList.add("hideUnPin");
    this.isPinned = !this.isPinned;
    if (this.sidebar?.nativeElement.classList.contains('show')) {
      this.appcomponentservice.setIsPinChecked(true);
    }
    else {
      this.appcomponentservice.setIsPinChecked(false);
    }
  }

  handleMouseLeave() {
    if(this.verifyPreviewvalue){
    const chatInput = document.getElementById("chatinput")
    const dynamicContent = document.getElementById("dynamicContent")
    const chatMainContainer = document.getElementById("chatMainContainer");
    const sidebar = this.sidebar?.nativeElement;
    const mainContent = this.mainContent?.nativeElement;
    //const dynamicContent = this.dynamicContent?.nativeElement;
    //const chatInput = this.chatInput?.nativeElement;
    if (!this.isPinned) {
      sidebar.classList.add('hidden');
      sidebar.classList.remove('show');
      mainContent?.classList.add('main-content-expand');
      dynamicContent?.classList.add('dynamic-content-expand');
      chatInput?.classList.add('chat-input-expand');
      chatMainContainer?.classList.add('chat-main-container-expand');
    } else {
      sidebar.classList.add('show');
      sidebar.classList.remove('hidden');
      mainContent?.classList.remove('main-content-expand');
      dynamicContent?.classList.remove('dynamic-content-expand');
      chatInput?.classList.remove('chat-input-expand');
      chatMainContainer?.classList.remove('chat-main-container-expand');
    }
    if (this.sidebar?.nativeElement.classList.contains('show')) {
      this.appcomponentservice.setIsPinChecked(true);
    }
    else {
      this.appcomponentservice.setIsPinChecked(false);
    }
  }
  else return;
  }

  openUserCommunityLink(): void {
    window.open(this.teamsChannelUrl, '_blank');
  }

  openSystemSettingsModal(event: Event) {
    if (this.appcomponentservice.isModelOpen){
      this.modalService.close();
      this.appcomponentservice.setIsModelOpen(false);
    }

    if (this.isSystemSettingsModalOpen) {
      event.preventDefault();
      return;
    }
    this.isSystemSettingsModalOpen = true;
    this.appcomponentservice.setIsModelOpen(true);
    let modalRef = this.modalService.open(
      SystemsettingsComponent, { isFooter: true, size: 'lg', isPinned: this.isPinned }).onClosed().subscribe(
        res => {
          this.appcomponentservice.setIsModelOpen(false);
          this.isSystemSettingsModalOpen = false;
          if (res != undefined) {
            this.appcomponentservice.setSystemSettings({
              "systemMessage": res.systemprompt,
              "responseStyle": res.personality,
              "userQueryLength": res.responselength
            });
          }
        });


  }
  @HostListener('document:mousemove', ['$event'])
  handleMainContentMouseLeave(event: MouseEvent) {
    if (this.isPinned) return;

    if (event.clientX <= 20) { // Adjust threshold if needed
      const chatInput = document.getElementById("chatinput")
      const dynamicContent = document.getElementById("dynamicContent")
      const chatMainContainer = document.getElementById("chatMainContainer");
      if (this.isPinned) {
        this.sidebar?.nativeElement.classList.add('hidden');
        this.sidebar?.nativeElement.classList.remove('show');
        this.mainContent?.nativeElement.classList.add('main-content-expand');
        dynamicContent?.classList.add('dynamic-content-expand');
        chatInput?.classList.add('chat-input-expand');
        chatMainContainer?.classList.add('chat-main-container-expand');

      } else {
        this.sidebar?.nativeElement.classList.add('show');
        this.sidebar?.nativeElement.classList.remove('hidden');
        this.mainContent?.nativeElement.classList.remove('main-content-expand');
        dynamicContent?.classList.remove('dynamic-content-expand');
        chatInput?.classList.remove('chat-input-expand');
        chatMainContainer?.classList.remove('chat-main-container-expand');

      }
      if (this.sidebar?.nativeElement.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      }
      else {
        this.appcomponentservice.setIsPinChecked(false);
      }

    }

  }

  ngAfterViewInit() {
    // // Initialize Bootstrap tooltip
    // const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    // tooltipTriggerList.map(function (tooltipTriggerEl) {
    //   return new (window as any).bootstrap.Tooltip(tooltipTriggerEl, {
    //     html: true
    //   });
    // });

    const sidebar = this.sidebar?.nativeElement;
    sidebar?.addEventListener('mouseleave', this.handleMouseLeave.bind(this));

    // const mainContent = this.mainContent?.nativeElement;
    // mainContent?.addEventListener('mouseleave', this.handleMainContentMouseLeave.bind(this));
  }
  ngAfterContentChecked(): void {

  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblRecentlyUsedAgents = "Agents utilisés récemment";
            this.lblChat = "Conversation";
            this.lblChatHistory = "Historique de conversations"
            this.lblRecent10Conversations = "Seules les 10 sessions les plus récentes seront disponibles pour reprendre et continuer.";
            this.lblSettings = "Configurations";
            this.lblUserCommunity = "Communauté d'utilisateurs";
            this.lblChatSettings = "Paramètres de Chat";
            this.lblNewChat = "Nouvelle conversation";
            this.lblWhatsNew = "Quoi de neuf ?";
            break;

          default:
            this.lblRecentlyUsedAgents = "Recently used agents";
            this.lblChat = "Chat";
            this.lblChatHistory = "Chat History";
            this.lblRecent10Conversations = "Only the most recent 10 sessions will be available for revisit and resume.";
            this.lblSettings = "Settings";
            this.lblUserCommunity = "User Community";
            this.lblChatSettings = "Chat Settings";
            this.lblNewChat = "New Chat";
            this.lblWhatsNew = "What's New?";
            break;
        }
        // alert(locale)
      });
  }

  ngOnInit(): void {
    this.appcomponentservice.setIsModelOpen(true);

    this.serviceFuncSetIsPinned();
    this.verifyPreviewUSer();

   // this.pinSidebar();
    this.manageLocale();
    this.systemModel = "gpt4o"
    this.appcomponentservice.setSystemModel(this.systemModel);
    this.appcomponentservice.setSystemSettings({ "systemMessage": "", "responseStyle": 0, "userQueryLength": "" });
    if (this.selectedLanguage == 'FR')
      this.https.get('assets/i18n/fr.json').subscribe((data: ITranslation) => this.i18n.setLocale('FR', data));
    else
      this.https.get('assets/i18n/en.json').subscribe((data: ITranslation) => this.i18n.setLocale('en', data));

  }

  verifyPreviewUSer() {
    this.chatService.verifyPreviewUser()
      .subscribe((response: any) => {
        this.appcomponentservice.setIsModelOpen(false);
        if (response.result == "Success") {
            this.verifyPreviewvalue = true;
          // if (this.appcomponentservice.isModelOpen){
          //   this.modalService.close();
          //   this.appcomponentservice.setIsModelOpen(false);
          // }
          this.appcomponentservice.setIsModelOpen(false);
          this.pinSidebar();

            // this.modalService.open(PreviewPopupComponent).onClosed().subscribe(res => {
            //   this.appcomponentservice.setIsModelOpen(false);
            //    });
        }
        else {
          this.verifyPreviewvalue = false;
          this.appcomponentservice.setIsModelOpen(false);

        }
      }),
      (error: any) => {
        console.log("error", error);
      }
  }
  documentAnalyzerRedirect() {
    this.router.navigate(['/document-analyzer']);
    this.hidemodelSelect = false;
  }
  gendchatRedirect() {
    this.router.navigate(['/']);
    this.hidemodelSelect = true;

  }


  onLanguageChange(event: any) {
    // this.selectedLanguage = event.target.checked ? 'FR' : 'EN';
    // const selectElement = event.target as HTMLSelectElement;
    // this.selectedLanguage = selectElement.value;
    this.selectedLanguage = event.target.value;
    // You can use a service to share this value across components
    this.languageService.setCurrentLanguage(this.selectedLanguage);
    this.i18n.setLocale(this.selectedLanguage, EN);
    if (this.selectedLanguage == 'FR')
      this.https.get('assets/i18n/fr.json').subscribe((data: ITranslation) => this.i18n.setLocale('FR', data));
    else
      this.https.get('assets/i18n/en.json').subscribe((data: ITranslation) => this.i18n.setLocale('en', data));

    if (this.systemModel == "gpt4o") {
      this.selectedLanguage == "EN" ? this.text = "Token Limit: 13000\nSpeed: High\nReasoning: High\nTraining Upto: Oct 2023" : this.text = "Limite de jetons: 13000\nVitesse: Élevée\nRaisonnement: Élevé\nFormation jusqu'à: Oct 2023";
    }
    else {
      this.selectedLanguage == "EN" ? this.text = "Token Limit: 13000\nSpeed: High\nReasoning: Medium\nTraining Upto: Sep 2021" : this.text = "Limite de jetons: 13000\nVitesse: Élevée\nRaisonnement: Moyen\nFormation jusqu'à: Sep 2021";
    }
  }

  onClickSystemModel(event: any) {
    event.stopPropagation();
  }


  changeSystemModel() {
    console.log(this.systemModel)
    this.appcomponentservice.setSystemModel(this.systemModel);

    if (this.systemModel == "gpt4o") {
      this.selectedLanguage == "EN" ? this.text = "Token Limit: 13000\nSpeed: High\nReasoning: High\nTraining Upto: Oct 2023" : this.text = "Limite de jetons: 13000\nVitesse: Élevée\nRaisonnement: Élevé\nFormation jusqu'à: Oct 2023";
    }
    else {
      this.selectedLanguage == "EN" ? this.text = "Token Limit: 13000\nSpeed: High\nReasoning: Medium\nTraining Upto: Sep 2021" : this.text = "Limite de jetons: 13000\nVitesse: Élevée\nRaisonnement: Moyen\nFormation jusqu'à: Sep 2021";
    }
  }

  newChat() {
    this.appcomponentservice.NewChatFunction();  // Notify child component
    // Trigger the reset event
    this.resetChatService.triggerReset();
  }


  options: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'right',
    tooltipIndent: 15,
    tooltipIsOutsideClick: false,
    tooltipHasBeak: true,
    tooltipIsDisabled: false,
    tooltipIsDynamic: false,
    tooltipCustomClass: '',
    tooltipType: 'regular',
    tooltipSize: 'lg',
  };
}
